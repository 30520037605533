nav.sb-topnav {
    /* background: linear-gradient(to right, #4099ff, #73b4ff); */
    background: linear-gradient(90deg, #4099ff, #73b4ff);
    color: rgba(255, 255, 255, 0.8);
}
img.admin-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
button#dropdown-item-button {
    background: transparent;
    border: none;
}
button#dropdown-item-button:focus{
    outline: none;
    box-shadow: none;
}
.sb-nav-fixed .dropdown-item{
    border-radius: 2px;
    color:#222;
}
.sb-nav-fixed .dropdown-menu{
    padding: 10px;
    border-radius: 5px;
}
.sb-nav-fixed .dropdown-item:hover{
    background: #4099ff;
    color:#fff;
    box-shadow: 0 15px 8px -9px rgba(0,0,0,.25) !important;
}

div#layoutSidenav_nav{
    box-shadow: 2px 0 2.94px 0.06px rgb(4 26 55 / 16%);
    transition: all 0.05s ease-in-out;
}
.my-custom-dropdown .dropdown-toggle::after {
    display: none !important;
    visibility: visible;
}
div#layoutSidenav_nav {
    box-shadow: 2px 0 2.94px 0.06px rgb(4 26 55 / 16%);
    transition: all 0.05s ease-in-out;
    height: 100vh;
    width: 225px;
  }
.btn-sm {
    padding-left: 24px !important;
    padding-right: 24px !important;
}
.linknav{
    text-decoration: none !important;
    color: #666;
    list-style-type: none !important;
}



.dropdownsub{
    text-decoration: none !important;
    background-color: transparent;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    display: list-item;
    position: relative;
    color: #666;
}


.list{
    list-style-type: none;
    margin-top: 10px;
    margin-left: 10px;
}
.navbar11{
    /* margin-top: 6px; */
    border-left:transparent solid 4px;
    &:after{

    }
}
.activeBg {
    background: hsla(0,0%,79%,.3);
    border-left:#4099ff solid 4px; 
 }
.activeBg1{
    font-weight: bolder;
    font-weight: 900 !important;
}
.navbar11:hover{
    cursor: pointer;
}
.sb-nav-fixed .sb-topnav{
    z-index: 99999;
    margin-top: -6px;
}
.insideDive{
    border-left: 1px solid rgba(202, 202, 202, 0.527);
    margin-left: -33px;
}
.imgnav{
    width: 12px;
    height: 12px;
    margin-right: 14px;
    transition: 0.09s linear  !important;
}
.imgnav1{
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: -8px;
    transition: 0.09s linear  !important;
}
.heading{
    padding-left: 30px;
    color: #666;
    font-family: "Poppins",sans-serif;
    padding: 13px 0px 13px 13px;
    font-size: small;
    font-weight: bold;
    position: relative;
    font-size: 15px;
    svg {
        width: 17px;
        height: 17px;
        fill: #666 !important;
        
        ellipse,
        path,
        g{
            fill: #666 !important;
            stroke:#666 !important;
        }
    }
}

.my-custom-dropdown1 {
    margin-left: -20px;
}
.sidebar_menu_inner{
    list-style: none;
    padding-left: 0;
    position: relative;
    &:after{
        content: "";
        height:calc(100% - 19px);
        left: 25px;
        position: absolute;
        top: 0;
        background: rgba(185,199,212,.5);
        width: 1px;
    }
    li{
        position: relative;
        font-family: "Poppins",sans-serif;
        font-size: 14px;
        &:before{
            content: "";
            display: block;
            width: 22px;
            height: 1px;
            background-color: rgba(202, 202, 202, 0.527);
            position: absolute;
            left: 26px;
            top:50%;
            transform: translateY(-50%);
        }
    }
    .dropdownitem{
        text-decoration: none !important;
        background-color: transparent;
        font-family: "Poppins",sans-serif;
        font-size: 14px;
        display: list-item;
        position: relative;
        color: #666;
        padding: 10px 7px 10px 60px;
        &.active{
            font-weight: 700;
            color: #666;
            &:before{
                opacity: 1;
            }
        }
        &:hover{
            &:before{
                opacity: 1;
            }
        }
        &:before{
            border-radius: 50%;
            content: "";
            height: 6px;
            left: 44px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            background: #4099ff;
            opacity: 0;
        }
    }
}

::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

    * {
        scrollbar-width: thin;
        scrollbar-color:  #c5c5c5 #f6f6f6;
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    *::-webkit-scrollbar-track {
        background: #f6f6f6;
    }

    *::-webkit-scrollbar-thumb {
        background-color:  #c5c5c5;
        border-radius: 20px;
        border: 3px solid #f6f6f6;
    }